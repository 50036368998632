import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Post Conversation Analysis`}</h1>
    <ul>
      <li parentName="ul">{`After `}<a parentName="li" {...{
          "href": "/conversation",
          "title": "conversation"
        }}>{`conversation`}</a>{`, after `}<a parentName="li" {...{
          "href": "/export",
          "title": "export"
        }}>{`export`}</a>{`, after `}<a parentName="li" {...{
          "href": "/notebooks",
          "title": "notebooks"
        }}>{`notebooks`}</a>{`, the final output.`}</li>
      <li parentName="ul">{`See `}<a parentName="li" {...{
          "href": "/Steps-to-creating-post-conversation-analysis",
          "title": "Steps to creating post-conversation analysis"
        }}>{`Steps to creating post-conversation analysis`}</a>{` and `}<a parentName="li" {...{
          "href": "/Analysis",
          "title": "🔬 Analysis"
        }}>{`🔬 Analysis`}</a>{``}</li>
      <li parentName="ul">{`See CompDem `}<a parentName="li" {...{
          "href": "/services",
          "title": "services"
        }}>{`services`}</a>{``}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      